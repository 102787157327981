$black: #0c0c0c;
$white: #ffffff;
$contrast: #F26291;
$transition: .2s ease-in-out;

$dark: #202631;
$bright: #e0e0e0;

$small: 56.25rem;
$mobile: 31.25rem;

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-rendering: optimizeLegibility; }

body {
  margin: 0; }

html {
  color: $black;
  font-family: 'Work Sans', sans-serif; }

h1 {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-transform: uppercase; }

p {
  font-weight: 400;
  margin: 0; }

.loading {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.5px; }

.wrapper {
  background-color: $white;

  &.dark {
    background-color: $dark;

    p, h1 {
      color: $bright; }

    .modal-wrapper {
      .decorative-close,
      .info-icon {
        color: $bright; }

      .modal-info {
        .info-icon {
          background-color: $dark;
          &:hover {
            background-color: $white;
            color: $dark; } } }

      button {
        background-color: rgba(32, 38, 49, 0.75); } }

    .loading {
      color: $bright; }

    .main {
      .images {
        .image-box {
          &:focus {
            box-shadow: 0 0 0 0.25rem $dark, 0 0 0 0.3125rem $contrast; } } } } }

  .main {
    margin: 0 auto;
    min-height: 100vh;
    max-width: 87.5rem;
    padding: 4.6875rem 4.375rem 1.875rem;
    width: 100%;

    @media screen and (max-width: $small) {
      padding: 4.6875rem 2.5rem 1.875rem; }

    @media screen and (max-width: $mobile) {
      padding: 3.125rem 1.25rem 0.125rem; }

    .sub-head {
      font-size: 1.5rem;
      margin-bottom: 0.9375rem; }

    .info {
      font-size: 1rem; }

    .images {
      display: grid;
      grid-gap: 1.25rem;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 4.6875rem;

      @media screen and (max-width: $small) {
        grid-template-columns: repeat(2, 1fr); }

      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr); }

      .image-box {
        background: none;
        border: 0.125rem solid rgba(242, 98, 145, 0);
        cursor: zoom-in;
        outline-color: rgba(0, 0 ,0 ,0);
        outline-offset: 0.125rem;
        padding-bottom: 100%;
        position: relative;

        &:hover {
          border: 0.125rem solid $contrast; }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 0.25rem $white, 0 0 0 0.3125rem $contrast; }

        &:focus,
        &:hover {
          transition: opacity $transition, border $transition;

          .image-title {
            opacity: .9;
            transition: opacity $transition; } }

        .image {
          height: 100%;
          left: 0;
          object-fit: cover;
          position: absolute;
          top: 0;
          width: 100%; }

        .image-title {
          background-color: $black;
          bottom: 1.25rem;
          color: $white;
          font-family: monospace;
          left: 50%;
          opacity: .4;
          padding: 0.3125rem 0.625rem;
          position: absolute;
          transform: translateX(-50%);
          transition: opacity $transition; } } } }

  .modal-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    .decorative-close,
    .info-icon {
      align-items: center;
      border-radius: 50%;
      box-shadow: 0px 0px 0px 1px $contrast;
      color: $dark;
      display: flex;
      height: 30px;
      justify-content: center;
      position: absolute;
      top: 23px;
      width: 30px; }

    .decorative-close {
      font-size: 2rem;
      line-height: 1;
      pointer-events: none;
      right: 23px;
      z-index: 10; }

    .modal-info {
      background-color: rgba(255, 255, 255, 0);
      clip-path: circle(5% at 38px 38px);
      font-size: 20px;
      left: 0;
      max-width: 50vw;
      padding: 24px 75px;
      position: fixed;
      top: 0;
      transition: .2s ease-out;
      width: 650px;
      z-index: 10;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
        clip-path: circle(100%);
        transition: .2s ease-in; }

      .info-icon {
        cursor: help;
        left: 23px; }

      p {
        color: $black; }

      @media screen and (max-width: $small) {
        max-width: none;
        width: 100%; } }

    button {
      appearance: none;
      background-color: rgba(255, 255, 255, 0.75);
      border: none;
      cursor: pointer;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1; }

    img {
      height: auto;
      max-height: 90%;
      max-width: 90%;
      width: auto;
      z-index: 2; } } }

.dark-mode-button {
  background-color: $dark;
  bottom: 2rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 1.875rem;
  position: fixed;
  right: 1.25rem;
  width: 1.875rem;

  @media screen and (max-width: $small) {
    right: 0.375rem; }

  &.active {
    background-color: $white; }

  &:hover {
    animation: pulse 1s ease-in-out infinite alternate;
    transition: .3s ease-out; } }

.screen-reader-only {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px; }

.animate {
  animation: fade-in .5s ease-in-out forwards;
  opacity: 0; }

@keyframes pulse {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  85% {
    transform: translateY(0); }
  100% {
    opacity: 1;
    transform: translateY(0); } }
